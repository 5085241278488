// @flow
import get from 'lodash/get'

import Datetime from '@/v2/modules/Datetime'

import DayScheduleFragment from '@/v2/graphql/queries/fragments/DayScheduleInfo.gql'

const appendAppointment = (cache, appointment) => {
  if (appointment.id) {
    const startsAt = Datetime.fromISO(get(appointment, 'startsAt'))
    const fragmentId = `DaySchedule:${startsAt.toQueryFormat()}`

    try {
      const result = cache.readFragment({
        id: fragmentId,
        fragment: DayScheduleFragment,
        fragmentName: 'DayScheduleInfo',
      })

      const alreadyInList = result.appointments.find(
        e => e.id === appointment.id
      )

      if (!alreadyInList) {
        cache.writeFragment({
          id: fragmentId,
          fragment: DayScheduleFragment,
          fragmentName: 'DayScheduleInfo',
          data: {
            ...result,
            appointments: [...result.appointments, appointment],
          },
        })
      }
    } catch (e) {
      // Trying to write an undefined cache. Ignoring exception.
    }
  }
}

export default appendAppointment
