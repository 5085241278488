// @flow
import get from 'lodash/get'

import Datetime from '@/v2/modules/Datetime'

import AppointmentsCountInfo from '@/v2/graphql/queries/fragments/AppointmentsCountInfo.gql'

const updateAppointmentsCounter = (cache, appointment, amount) => {
  if (appointment) {
    const appointmentDate = Datetime.fromISO(get(appointment, 'startsAt'))
    const fragmentId = `DaySchedule:${appointmentDate.toQueryFormat()}`

    try {
      const countFragment = cache.readFragment({
        id: fragmentId,
        fragment: AppointmentsCountInfo,
        fragmentName: 'AppointmentsCountInfo',
      })

      cache.writeFragment({
        id: fragmentId,
        fragment: AppointmentsCountInfo,
        fragmentName: 'AppointmentsCountInfo',
        data: {
          ...countFragment,
          appointmentsCount: countFragment.appointmentsCount + amount,
        },
      })
    } catch (e) {
      // Trying to write an undefined cache. Ignoring exception.
    }
  }
}

export default updateAppointmentsCounter
